body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "TT Interphases", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 640px;
  margin: 0 auto;
  background-color: #f2f2f2;
  height: 100vh !important;
  overscroll-behavior: contain;
  overscroll-behavior-y: contain;
}
.App {
  background: #fff;
  height: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ~~~~~~~~~~~~~HEADER COMPONENT~~~~~~~~~~~~~ */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.headerText {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #1a1a1a !important;
}

.popUpHeaderText {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #1a1a1a !important;
}

/* ~~~~~~~~~~~~~FOOTER COMPONENT~~~~~~~~~~~~~ */
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  border-bottom: 2px solid #ed6b23;
}

.footerText {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #1a1a1a !important;
  margin-right: 6px;
}

/* ~~~~~~~~~~~~~ALL THE INPUT FIELDS (Dropdown/InputText box) LABELS COMPONENT~~~~~~~~~~~~~ */
.inputLabels {
  font-weight: bold !important;
  font-size: 12px !important;
  color: #515151 !important;
  margin-bottom: 8px !important;
  margin-top: 24px !important;
}

/* ~~~~~~~~~~~~~ALL THE SUBMIT BUTTONS ON PAGE END IN BLACK~~~~~~~~~~~~~ */
.submitButton {
  margin-top: 20px !important;
  height: 48px !important;
  background: #0047A9 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  color: #fff !important;
  box-shadow: none !important;
}

.submitButton-secondary {
  margin-top: 24px !important;
  height: 48px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  color: #007dfe !important;
  border-color: #007dfe !important;
  border: 1px solid !important;
}

.submitButton-secondary-2 {
  margin-top: 24px !important;
  height: 48px !important;
  background: #fff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  color: #007dfe !important;
  border-color: #007dfe !important;
  border: 0px solid !important;
}

.submitButtonDisabled {
  margin-top: 24px !important;
  height: 48px !important;
  background: #bcd4f9 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  color: #fff !important;
}

.submitBlackButtonPopUp {
  height: 48px !important;
  background: #0047A9 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  color: #fff !important;
}

/* ~~~~~~~~~~~~~ALL THE CARDS ON PAGE~~~~~~~~~~~~~ */
.paperCards {
  border-radius: 8px !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
  padding: 16px;
}

.dropShadow {
  border-radius: 8px !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.dropShadowError {
  border: 1px solid !important;
  border-color: #ff0000 !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
}

/* ~~~~~~~~~~~~~ALL THE SELECT INPUTS~~~~~~~~~~~~~ */
.selectInputs {
  height: 56px !important;
  border-radius: 4px !important;
  margin-top: "24px";
  background: #f7f6f6;
}

.iconArrowInSelectInput {
  size: 15px !important;
  color: #1A1A1A !important;
}

.selectInputDataFont {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #1a1a1a !important;
  padding: 0px 5px 0px 5px;
  text-align: center;
  min-width: 15px;
}

.selectInputPlaceholder {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #CCCCCC;
  padding: 0px 5px 0px 5px;
}

.selectInputDataFont-slider {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #1a1a1a !important;
  padding: 0px 5px 0px 5px;
}

.searchAirportField {
  height: 48px !important;
  border-radius: 8px !important;
  background: #FFFFFF;
  padding: 8px 12px;
  border: 1px solid #DDDDDD;
  width: 100%;
}

.classOptions {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #1A1A1A !important;
}

.searchAirportFieldOnFocus {
  height: 48px !important;
  border-radius: 8px !important;
  padding: 8px 12px;
  background: #ffffff;
  border: 2px solid #1A1A1A;
  width: 100%;
  font-size: 16px !important;
  font-weight: bold !important;
}

.slider {
  margin-left: 0px !important;
  margin-right: 0px !important;
  color: #1a1a1a;
}

::placeholder {
  color: #ada6ae;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}

.bold {
  font-weight: 700;
}

.pointer {
  cursor: pointer;
}
.dropdownBox {
  min-width: 50%;
  max-width: 50%;
  min-height: 4em;
  max-height: 20em;
  position: absolute;
  z-index: 10;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
}

.phoneCodeListItem {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #ddd !important;
  padding: 12px 0 !important;
  margin: 5px;
}

.bg-white {
  background-color: #fff !important;
}
.flex {
  display: flex;
}
.item-center {
  align-items: center;
}
.text-right {
  text-align: right;
}
.color-app-blue {
  color: #0047A9;
}
.color-app-orange {
  color: #ff5e1f;
}
.label-1 {
  color: #666666;
  font-size: 14px !important;
}
.label-2 {
  color: #615a5a;
  font-size: 16px;
}
.label-3 {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #1a1a1a;
}
.box-shadow {
  box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
}
.border-t-1 {
  border-top: 1px solid #ededed;
}

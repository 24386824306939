.vl-1::after{
    content:"";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 50%;
    height: 100%;
    height: inherit;
    border-left: 2px dotted #CCCCCC;
}

.vl-1 {
    position: relative;
    margin: 10px auto;
    width: 50px;
}

.icon {
    text-align: center;
    width: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.circleIcon{
    font-size: 14px !important;
    background-color: white;
    z-index: 3;
    position: absolute;
    right: 34%;
    color: #CCCCCC
}

.iconBlue{
    color: #0047A9;
}

.tSecSize{
    color: #615A5A;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 15px !important;
}

.tMargin1{
    margin-top:5px !important;
}

.tMargin2{
    margin-top:13px !important;
}

.tMargin3{
    margin-top:1.25em !important;
}

.tFlight{
    font-weight: 700 !important;
    font-size: 14px !important;
    bottom: 1px;
    position: relative;
}

.paperW{
    background: #F7F6F6 !important;
    min-height: 8em;
}